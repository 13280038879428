<template>
    <el-radio-group v-model="data.value[0]" class="judge-option">
        <el-radio :label="index" v-for="(item, index) in data.option">
            <el-input v-model="item.name" placeholder="请输入内容" class="single-input"></el-input>
        </el-radio>
    </el-radio-group>
</template>

<script>
    export default {
        name: "Judge",
        props: ['data'],
        data() {
            return {

            }
        }
    }
</script>

<style scoped lang="scss">
    .judge-option {
        width: 100%;
        .el-radio {
            display: block;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .single-input {
            width: 200px;
        }
    }
</style>