<template>
    <div>
        <div class="fill-item" v-model="data.value" v-for="(item, index) in data.option">
            <span class="sort-item">{{index + 1 | formatNumber}}</span>
            <el-input v-model="item.name" placeholder="请输入内容" class="fill-input"></el-input>
            <i class="el-icon-close" @click="del(index)"></i>
        </div>
        <el-button type="primary" size="small" @click="addOption">+ 添加选项</el-button>
    </div>
</template>

<script>
    export default {
        name: "Fill",
        props: ['data'],
        filters: {
            // 试题答案格式
            formatNumber(nums) {
                const numbers = {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D',
                    '5': 'E',
                    '6': 'F',
                }
                return numbers[nums]
            },
        },
        data() {
            return {

            }
        },
        methods: {
            //添加选项
            addOption() {
                let option = {
                    name: ''
                }
                if (this.data.option.length < 4) {
                    this.data.option.push(option);
                } else {
                    this.$message({
                        message: '最多只能设置四个空！',
                        type: 'warning'
                    });
                }
            },
            del(index) {
                this.data.option.splice(index, 1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .fill-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .fill-input {
            width: 700px;
            margin: 0 12px;
        }
    }
</style>