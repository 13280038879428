<template>
    <el-scrollbar class="main-wrapper">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin-left: 20px">
            <el-breadcrumb-item>理论题</el-breadcrumb-item>
            <el-breadcrumb-item>添加试题</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form :model="subjectForm" :rules="rules" ref="subjectForm" label-width="170px" class="subject-ruleForm">
            <el-form-item label="题型" prop="type">
                <el-radio-group v-model="subjectForm.type" size="small" @change="selectType" class="radioGroup-yellow">
                    <el-radio-button :label=1 :disabled="questionId > 0">单选题</el-radio-button>
                    <el-radio-button :label=2 :disabled="questionId > 0">多选题</el-radio-button>
                    <el-radio-button :label=3 :disabled="questionId > 0">判断题</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="难度" prop="type">
                <el-radio-group v-model="subjectForm.difficulty" size="small" class="radioGroup-yellow">
                    <el-radio-button :label=1>容易</el-radio-button>
                    <el-radio-button :label=2>较易</el-radio-button>
                    <el-radio-button :label=3>普通</el-radio-button>
                    <el-radio-button :label=4>较难</el-radio-button>
                    <el-radio-button :label=5>困难</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="分类" prop="category_id">
                <el-cascader v-model="subjectForm.category_id" style="width: 420px" :props="specializedKeyObj" :options="specializedList" clearable placeholder="请选择分类"></el-cascader>
            </el-form-item>
            <el-form-item label="题目" prop="title" :class="subjectForm.type === 4 ? 'subject-fill' : 'subject-quill'">
                <vue-tinymce ref="tiny_item1" :setting="setting" v-model="subjectForm.title"></vue-tinymce>
                <div style="line-height: 21px; color: red; margin-top: 5px">
                    <span>*注:(1)标题不用写序号，如：1.  2.  3. </span>
                    <span v-if="subjectForm.type === 4">，(2)[英文状态]的下划线为填空部分，4个下划线"____"为一个空，连续两个空用逗号或空格隔开。</span>
                </div>
            </el-form-item>
            <el-form-item label="答案选项" prop="answer" :class="subjectForm.type === 5 ? 'subject-short-answer' : ''">
                <SingleChoice :data="subjectForm.options" v-if="subjectForm.type === 1"></SingleChoice>
                <MultipleChoice :data="subjectForm.options" v-if="subjectForm.type === 2"></MultipleChoice>
                <Judge :data="subjectForm.options" v-if="subjectForm.type === 3"></Judge>
                <Fill :data="subjectForm.options" v-if="subjectForm.type === 4"></Fill>
            </el-form-item>
            <el-form-item label="题目解析" prop="analysis" class="subject-quill">
                <vue-tinymce :setting="setting" v-model="subjectForm.analysis"></vue-tinymce>
                <div style="line-height: 21px; color: red; margin-top: 5px">
                    <span v-if="subjectForm.type === 5">*注：问答题的题目解析必须填写！</span>
                </div>
            </el-form-item>
            <el-form-item label="是否按顺序判断答案" prop="is_order_answer" v-if="subjectForm.type === 4">
              <el-radio-group v-model="subjectForm.is_order_answer" size="small" class="radioGroup-yellow">
                <el-radio-button :label=0>否</el-radio-button>
                <el-radio-button :label=1>是</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="标签">
                <el-select filterable v-model="tagsValue" placeholder="请选择标签组" style="width: 250px" @change="changeTags">
                    <el-option v-for="(tagsItem, tagsIndex) in tagsList" :key="`tagsList_${tagsIndex}`"
                               :label="tagsItem.tag_name" :value="tagsItem.tag_id"></el-option>
                </el-select>
                <el-select filterable v-model="tagsLevel2Value" placeholder="请选择标签" style="width: 250px; margin-left: 10px" @change="changeLevel2Tags">
                    <el-option v-for="(tagsItem, tagsIndex) in tagsLevel2List" :key="`tagsLevel2List_${tagsIndex}`"
                               :label="tagsItem.tag_name" :value="tagsItem.tag_id"></el-option>
                </el-select>
                <el-tag closable v-for="tag in theTags" @close="delTags(tag)" class="the_tags" style="margin: 0 5px;" v-if="tag">{{tag.tag_name}}</el-tag>
            </el-form-item> -->
            <el-form-item>
                <el-button type="primary" @click="subjectSubmitForm('subjectForm')">保存</el-button>
                <el-button v-if="!questionId" @click="resetForm('subjectForm')">取消</el-button>
            </el-form-item>
        </el-form>
    </el-scrollbar>
</template>

<script>
    import { alCate, theoryTopicGetDetail, theoryTopicEdit, theoryTopicUploadRtImg } from '@/utils/apis.js'
    import _ from "underscore";

    import SingleChoice from '@/components/admin/subjectType/SingleChoice'
    import MultipleChoice from '@/components/admin/subjectType/MultipleChoice'
    import Judge from '@/components/admin/subjectType/Judge'
    import Fill from '@/components/admin/subjectType/Fill'

    export default {
        components: {
            SingleChoice,
            MultipleChoice,
            Judge,
            Fill,
        },
        created() {
            this.getSpecializedList();
            this.getTagList()
            if (this.questionId) {
                this.getInfo()
            }
        },
        data () {
            return {
                questionId: this.$route.query.id || null,
                //试题data
                subjectForm: {
                    type: 1,
                    difficulty: 1,
                    category_id: [],
                    title: '',
                    options: {
                        option: [],
                        value: []
                    },
                    analysis: '',
                    is_order_answer:0,
                },
                rules: {
                    type: [
                        { required: true, message: '请选择题型', trigger: 'change' }
                    ],
                    difficulty: [
                        { required: true, message: '请选择难度', trigger: 'change' }
                    ],
                    category_id: [
                        { required: true, message: '请选择分类', trigger: 'change' }
                    ],
                    is_order_answer: [
                      { required: true, message: '请选择是否按顺序判断答案', trigger: 'change' }
                    ],
                },
                //选择专业分类
                specializedList: [],
                specializedKeyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
                //选择标签组列表
                tagsValue: '',
                tagsList: [],
                tagsLevel2Value: '',
                tagsLevel2List: [],
                //标签
                theTags: [],
                setting: {
                    menubar: false,
                    // axupimgs
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 300,

                    //上传图片
                    images_upload_handler: (blobInfo, success, failure, progress) => {
                        let formData = new FormData()
                        formData.append('rt_img', blobInfo.blob())
                        theoryTopicUploadRtImg(formData).then((res) => {
                            this.$message.success(res.msg)
                            success(res.data.url)
                        }).catch((err) => {
                            if (document.querySelector('.tox-dialog__busy-spinner')) { // 单图片上传
                                (document.querySelector('.tox-dialog__busy-spinner').style.display = 'none')
                                // this.$message.warning('上传失败1')
                            } else { // 多图片上传
                                failure('上传失败2')
                            }
                        })
                    }
                },
            }
        },
        methods: {
            getInfo() {
                let params = {
                    theory_topic_id: this.questionId
                }
                theoryTopicGetDetail(params).then((res) => {
                    this.subjectForm = {
                        type: res.data.theory_topic_data.type,
                        difficulty: res.data.theory_topic_data.difficulty,
                        category_id: res.data.theory_topic_data.category_id_arr,
                        title: res.data.theory_topic_data.title,
                        options: {
                            option: JSON.parse(res.data.theory_topic_data.options),
                            value: JSON.parse(res.data.theory_topic_data.answer)
                        },
                        analysis: res.data.theory_topic_data.analysis,
                        is_order_answer:res.data.theory_topic_data.is_order_answer
                    }
                    // this.theTags = res.data.theory_topic_data.theory_topic_tag
                })
            },
            //选择题型
            selectType(val) {
                this.subjectForm.type = val;
                if (val === 3) {
                    this.subjectForm.options.option = [
                        {name: '对'},{name: '错'}
                    ]
                } else {
                    this.subjectForm.options.option = []
                    this.subjectForm.options.value = []
                }
            },
            //专业分类
            getSpecializedList() {
                let params = {
                    type: 3
                }
                alCate(params).then((res) => {
                    this.clearEmptyCate(res.data);
                    this.specializedList = res.data
                })
            },
            clearEmptyCate(arr) {
                arr.forEach(item => {
                    item.children.length <= 0 ? this.$delete(item, 'children') : this.clearEmptyCate(item.children);
                })
            },
            // 标签列表
            getTagList() {
                theoryTopicGetDetail().then((res) => {
                    this.tagsList = res.data.tag_data
                })
            },
            //标签组选择
            changeTags(value) {
                this.tagsLevel2Value = ''
                this.tagsLevel2List = []
                let tmp = this.tagsList.find(item => {
                    return item.tag_id === value
                })
                if (tmp) {
                    this.tagsLevel2List = tmp.children
                }
            },
            // 标签选择
            changeLevel2Tags(value) {
                let currentTmp = this.tagsLevel2List.find(item => {
                    return item.tag_id === value
                })
                let hasTmp = this.theTags.find(item => {
                    return item.tag_id === value
                })
                if (hasTmp) {
                    this.$message.warning('该标签已存在')
                } else {
                    this.theTags.push(currentTmp)
                }
            },
            //删除标签
            delTags(tag) {
                this.theTags.splice(this.theTags.indexOf(tag), 1);
                this.tagsValue = ''
                this.tagsLevel2Value = ''
            },
            //保存
            subjectSubmitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //选项
                        let answer_option = this.subjectForm.options.option;
                        // 判断题目是否为空
                        if(!this.subjectForm.title){
                            this.$message.warning('请先输入题目'); return
                        }
                        // 判断题目字数
                        let editor = this.$refs.tiny_item1.editor;
                        if (editor.getContent({ format: 'text'}).length < 3) {
                            return this.$message.warning('题目不能少于3个字符')
                        }

                        //填空题
                        if (this.subjectForm.type === 4) {
                            //匹配下划线
                            let titleMatch = this.subjectForm.title.match(/\____+/g);
                            if(titleMatch !== null) {
                                if(titleMatch.length !== answer_option.length) {
                                    this.$message.warning('设置的空和答案不一致');return
                                }
                            } else {
                                this.$message.warning('尚未设置空处');return
                            }
                        }
                        //判断是否有设置选项
                        if (this.subjectForm.type < 5) {
                            if (answer_option.length === 0) {
                                this.$message.warning('还未设置选项'); return
                            }
                        }
                        //判断是否有设置选项及正确答案
                        for (let i = 0; i < answer_option.length; i++) {
                            let element = answer_option[i];
                            if(!element.name){
                                this.$message.warning('答案不能为空');return
                            }

                            let correct_answer = this.subjectForm.options.value;
                            if (this.subjectForm.type < 4) {
                                if(correct_answer.length === 0) {
                                    this.$message.warning('还未设置正确答案'); return
                                }
                            }
                        }
                        //判断是否有设置问答题的题目解析
                        let askAnswer_answer = this.subjectForm.analysis;
                        if (this.subjectForm.type === 5) {
                            if(askAnswer_answer === '') {
                                this.$message.warning('问答题的题目解析必须填！'); return
                            }
                        }

                        let formData = new FormData();
                        formData.append('type', this.subjectForm.type);
                        formData.append('difficulty', this.subjectForm.difficulty);
                        formData.append('category_id', this.subjectForm.category_id[this.subjectForm.category_id.length - 1]);
                        formData.append('title', this.subjectForm.title);
                        formData.append('options', JSON.stringify(this.subjectForm.options.option));
                        formData.append('answer', JSON.stringify(this.subjectForm.options.value));
                        formData.append('analysis', this.subjectForm.analysis);
                        formData.append('is_order_answer', this.subjectForm.is_order_answer);

                        // if (this.theTags.length === 0) {
                        //     this.$message({
                        //         type: 'warning',
                        //         message: '请选择标签！'
                        //     });
                        //     return false;
                        // } else {
                        //     let tag_tmp = [];
                        //     _.map(this.theTags, function (item) {
                        //         tag_tmp.push(item.tag_id);
                        //     });
                        //     formData.append('tag_id_str', tag_tmp.join(','));
                        // }
                        if (this.questionId) {
                            formData.append('theory_topic_id', this.questionId)
                        }

                        theoryTopicEdit(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.$router.push('/theory/index')
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.subjectForm = {
                    type: 1,
                    difficulty: 1,
                    category_id: [],
                    title: '',
                    options: {
                        option: [],
                        value: []
                    },
                    analysis: '',
                }
                this.tagsValue = ''
                this.tagsLevel2Value = ''
                this.tagsLevel2List = []
                this.theTags = []
            },
        },
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .subject-ruleForm {
        padding-top: 20px;
        padding-right: 54px;
        ::v-deep {
            .el-form-item__label {
                color: #333;
                font-size: 16px;
            }
        }
        .subject-short-answer {
            display: none;
        }
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    /*单选框组 样式覆盖*/
    .radioGroup-yellow {
        ::v-deep .el-radio-button {
            margin-left: 10px;
            .el-radio-button__inner {
                color: #333;
                border: 1px solid #DCDCDC;
                border-radius: 2px;
                padding: 9px 0;
                width: 62px;
                font-weight: normal;
                &:hover {
                    color: #2DC079;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:first-child, &:last-child {
                .el-radio-button__inner {
                    border-radius: 0;
                }
            }
            .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                color: #fff;
                background-color: #2DC079;
                border-color: #2DC079;
                box-shadow: -1px 0 0 0 #2DC079;
            }
            .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
                color: #666;
                background-color: #EDEDED;
                border-color: #D2D2D2;
            }
            .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner {
                background-color: #2DC079;
                border-color: #2DC079;
                color: #fff;
            }
        }
    }
</style>