<template>
    <div>
        <div class="multiple-option" v-if="data.option.length > 0">
            <el-checkbox-group v-model="data.value" class="multiple-checkbox" @change="sortAnswer">
                <el-checkbox :label="index" v-for="(item, index) in data.option">
                    <span class="sort-item">{{index + 1 | formatNumber}}</span>
                    <el-input v-model="item.name" placeholder="请输入内容" class="single-input"></el-input>
                    <i class="el-icon-close" @click.stop="del(index)"></i>
                </el-checkbox>
            </el-checkbox-group>
        </div>
        <el-button type="primary" size="small" @click="addOption">+ 添加选项</el-button>
    </div>
</template>

<script>
    export default {
        name: "MultipleChoice",
        props: ['data'],
        filters: {
            // 试题答案格式
            formatNumber(nums) {
                const numbers = {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D',
                    '5': 'E',
                    '6': 'F',
                }
                return numbers[nums]
            },
        },
        data() {
            return {

            }
        },
        methods: {
            //添加选项
            addOption() {
                let option = {
                    name: ''
                }
                if (this.data.option.length < 6) {
                    this.data.option.push(option);
                } else {
                    this.$message({
                        message: '最多只能添加六个选项！',
                        type: 'warning'
                    });
                }
            },
            del(index) {
                this.data.option.splice(index, 1);
            },
            // 给多选题答案排序
            sortNumber(a, b) {
                return a - b
            },
            sortAnswer(val) {
                this.data.value = val.sort(this.sortNumber)
            }
        }
    }
</script>

<style scoped lang="scss">
    .multiple-checkbox {
        margin-bottom: 10px;
        .el-checkbox {
            display: block;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .single-input {
            width: 700px;
        }
    }
    .sort-item {
        margin-right: 15px;
    }
    .el-icon-close {
        color: #A0A0A0;
        margin-left: 12px;
    }
</style>